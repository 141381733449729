/** @jsxImportSource @emotion/react */
import React, { forwardRef, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import { css } from '@emotion/react'

import close from '../../assets/icons/small-close.svg'

// Usage example
// -------------
// import Popup, { getTransition } from '../components/Popup'
//
// const [isPopupVisible, setPopupVisibility] = useState(true)
// const popupRef = useRef(null)
//
// return (
//   <CSSTransition
//     in={isPopupVisible}
//     unmountOnExit
//     nodeRef={popupRef}
//     timeout={500}
//     classNames={getTransition(500)}
//     appear
//     unmountOnExit
//   >
//     <Popup ref={popupRef} closeAction={Action} title="YEPA!">
//       <p>Un, dos, tres!</p>
//       <p>And again un dos tres</p>
//       <button onClick={() => setPopupVisibility(false)}>Hide</button>
//     </Popup>
//   </CSSTransition>
// )

export function getTransition(duration = 250): any {
    return {
        appear: css({
            '.popup': {
                opacity: 0,
                transform: 'translate(-5vw,-50vh) rotate(-10deg)',
            },
        }),
        appearActive: css({
            '.popup': {
                opacity: 1,
                transform: 'translate(0,0) rotate(0)',
                transition: `opacity ${duration}ms ease-in, transform ${duration}ms ease-in`,
            },
        }),
        enter: css({
            '.popup': {
                opacity: 0,
                transform: 'translate(-5vw,-50vh) rotate(-10deg)',
            },
        }),
        enterActive: css({
            '.popup': {
                opacity: 1,
                transform: 'translate(0,0) rotate(0)',
                transition: `opacity ${duration}ms ease-in, transform ${duration}ms ease-in`,
            },
        }),
        exit: css({
            '.popup': {
                transform: 'translate(0,0) rotate(0)',
                opacity: 1,
            },
        }),
        exitActive: css({
            '.popup': {
                opacity: 0,
                transform: 'translate(5vw,-50vh) rotate(10deg)',
                transition: `opacity ${duration}ms ease-out, transform ${duration}ms ease-out`,
            },
        }),
    }
}

const InteractivePopup = forwardRef(function Popup(
    {
        when,
        title,
        closeAction,
        children,
        height,
    }: {
        when: boolean
        title: string
        closeAction: any
        children: any
        height?: string
    },
    ref: any,
) {
    const nodeRef = useRef(null)
    const radius = '11px'

    return (
        <CSSTransition
            in={when}
            timeout={300}
            css={getTransition(300)}
            appear
            unmountOnExit
            nodeRef={nodeRef}
        >
            <div
                ref={nodeRef}
                css={css({
                    position: 'absolute',
                    zIndex: 20,
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backdropFilter: 'blur(15px)',
                    backgroundColor: 'rgba(0,0,0,.7)',
                })}
            >
                <div
                    className="popup"
                    css={css({
                        width: '45%',
                        minWidth: '500px',
                        maxWidth: '900px',
                        height: height ? height : '60%',
                        minHeight: '500px',
                        maxHeight: '600px',
                        textAlign: 'center',
                        color: 'var(--almost-black)',
                        lineHeight: '1.23',
                        borderRadius: radius,
                        backgroundColor: '#fff',
                        display: 'flex',
                    })}
                >
                    <div
                        css={css({
                            flex: 1,
                            margin: '0 83px 0 0',
                            padding: '27px 0 0 0',
                            backgroundColor: '#fff3f1',
                            borderRadius: `${radius} 0 0 ${radius}`,
                        })}
                    >
                        <h2
                            css={css({
                                width: '297px',
                                flexGrow: 0,
                                margin: '0 47px 29px 49px',
                                fontFamily: 'FoundersGroteskMedium',
                                fontSize: '34px',
                                fontWeight: 500,
                                fontStretch: 'normal',
                                fontStyle: 'normal',
                                lineHeight: 'normal',
                                letterSpacing: 'normal',
                                textAlign: 'left',
                                color: '#111237',
                            })}
                        >
                            {title}
                        </h2>
                        {children[0]}
                    </div>
                    <div
                        css={css({
                            flex: 1,
                            padding: '10px',
                            paddingRight: '83px',
                        })}
                    >
                        <img
                            alt="close button icon"
                            css={css({
                                float: 'right',
                                left: '65px',
                                top: '10px',
                                padding: '0px',
                                position: 'relative',
                                cursor: 'pointer',
                            })}
                            src={close.toString()}
                            onClick={closeAction}
                        />
                        <div
                            css={css({
                                p: {
                                    marginBottom: 0,
                                    fontSize: '22px',
                                },
                                'button, .button': {
                                    display: 'inline-block',
                                    verticalAlign: 'top',
                                    minWidth: '30%',
                                    padding: '9px 18px',
                                    margin: '40px 5px 0',

                                    backgroundColor: 'var(--color-button)',
                                    border: '1px solid var(--color-button)',
                                    borderRadius: '55px',

                                    color: '#fff',
                                    fontFamily: 'var(--font-medium)',
                                    fontSize: '16px',
                                    textDecoration: 'none',

                                    transition: 'box-shadow ease-in 200ms',
                                },
                                'button:hover, .button:hover': {
                                    boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.7)',
                                },
                                'button.danger, .button.danger': {
                                    backgroundColor: 'var(--color-danger)',
                                    border: '1px solid var(--color-danger)',
                                },
                                'button.cancel, .button.cancel': {
                                    backgroundColor: '#fff',
                                    border: '1px solid var(--almost-black)',
                                    color: 'inherit',
                                },
                            })}
                        >
                            {children[1]}
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>
    )
})

export default InteractivePopup
