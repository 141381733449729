import * as Sentry from '@sentry/browser'
import { MutableRefObject } from 'react'
import {
    LocalTrack,
    LocalTrackPublication,
    LocalVideoTrack,
    Participant,
    RemoteParticipant,
    RemoteTrack,
    RemoteTrackPublication,
    Room,
    Track,
} from 'twilio-video'

import { ParticipantRefs } from '../types/SamaApi'
import samaApi from './SamaApi'

type callback = () => void
type callbacks = { [key: string]: callback }

/**
 * Handle the Participant's media.
 * @param room - the Room that the Participants are joining
 * @param refs - the DOM references that will be used to attach the tracks
 * @param remoteConnectionCb - the callback that gets executed once the first remote participant has connected
 */
export function handleParticipants({
    room,
    localRef,
    participantRefs,
    remoteConnectedCb,
    remoteDisconnectedCb,
    dominantSpeakerChangedCb,
    updateNetworkQualityCb,
    trackStatusCb,
    remoteVideoSubscribedCb,
    remoteVideoUnsubscribedCb,
    remoteAudioSubscribedCb,
    remoteAudioUnsubscribedCb,
}: {
    room: Room
    localRef: MutableRefObject<any>
    participantRefs: ParticipantRefs[]
    remoteConnectedCb: callback
    remoteDisconnectedCb: callback
    dominantSpeakerChangedCb: (participant: Participant) => void
    updateNetworkQualityCb: (value: number, identity: string) => void
    trackStatusCb: (
        name: string,
        participant: Participant,
        track: Track,
        enabled: boolean,
    ) => void
    remoteVideoSubscribedCb: callback
    remoteVideoUnsubscribedCb: callback
    remoteAudioSubscribedCb: callback
    remoteAudioUnsubscribedCb: callback
}): void {
    // Handle the LocalParticipant's media.
    handleParticipant(
        room.localParticipant,
        localRef,
        updateNetworkQualityCb,
        trackStatusCb,
    )

    const handleRemoteParticipant = (participant: Participant) => {
        const empty = participantRefs.filter((ref) => !ref.identity)
        const existing = participantRefs.filter(
            (ref) => ref.identity === participant.identity,
        )

        let refData = null
        if (existing.length > 0) {
            refData = existing[0]
        } else if (empty.length > 0) {
            refData = empty[0]
        }
        if (refData) {
            refData.identity = participant.identity

            handleParticipant(
                participant,
                refData.ref,
                updateNetworkQualityCb,
                trackStatusCb,
                {
                    videoSubscribedCb: remoteVideoSubscribedCb,
                    videoUnsubscribedCb: remoteVideoUnsubscribedCb,
                    audioSubscribedCb: remoteAudioSubscribedCb,
                    audioUnsubscribedCb: remoteAudioUnsubscribedCb,
                },
            )
            remoteConnectedCb()
        }
    }

    // Subscribe to the media published by RemoteParticipants already in the room.
    room.participants.forEach(handleRemoteParticipant)

    // Subscribe to the media published by RemoteParticipants joining the room later.
    room.on('participantConnected', handleRemoteParticipant)

    room.on('participantDisconnected', (participant: RemoteParticipant) => {
        remoteDisconnectedCb()
        participantRefs = participantRefs.map((ref) => {
            if (ref.identity === participant.identity) {
                ref.identity = undefined
            }
            return ref
        })
    })

    room.on('dominantSpeakerChanged', (participant) => {
        dominantSpeakerChangedCb(participant)
    })

    window.onbeforeunload = () => {
        room.disconnect()
    }
}

/**
 * Handle the Participant's media.
 * @param participant - the Participant
 * @param ref - the DOM reference that will be used to attach the track
 */
export function handleParticipant(
    participant: Participant,
    ref: MutableRefObject<any>,
    updateNetworkQualityCb: (value: number, identity: string) => void,
    trackStatusCb: (
        name: string,
        participant: Participant,
        track: Track,
        enabled: boolean,
    ) => void,
    callbacks: callbacks = {},
): void {
    // Handle the TrackPublications already published by the Participant.
    participant.tracks.forEach((publication) => {
        const pub = publication as RemoteTrackPublication
        handleTrack(pub, ref, callbacks, trackStatusCb, participant)
        if (pub.track) {
            const pub = publication as RemoteTrackPublication
            trackStatusCb(
                'trackExists',
                participant,
                pub.track as Track,
                ((pub.isSubscribed && pub.isTrackEnabled) ||
                    pub.track?.isEnabled) ??
                    true,
            )
        }
    })

    // Handle theTrackPublications that will be published by the Participant later.
    participant.on('trackPublished', (publication) => {
        console.log('trackPublished')
        handleTrack(publication, ref, callbacks, trackStatusCb, participant)
        if (publication.track?.kind === 'video') {
            trackStatusCb(
                'trackPublished',
                participant,
                publication.track,
                true,
            )
        }
    })

    participant.on('trackSwitchedOff', (track: Track) => {
        trackStatusCb('trackSwitchedOff', participant, track, false)
    })
    participant.on('trackSwitchedOn', (track: Track) => {
        trackStatusCb('trackSwitchedOn', participant, track, true)
    })
    participant.on('trackEnabled', (track: Track) => {
        trackStatusCb('trackEnabled', participant, track, true)
    })
    participant.on('trackDisabled', (track: Track) => {
        trackStatusCb('trackDisabled', participant, track, false)
    })

    participant.on(
        'networkQualityLevelChanged',
        (networkQualityLevel: number) => {
            updateNetworkQualityCb(networkQualityLevel, participant.identity)
        },
    )
}

/**
 * Handle to the TrackPublication's media.
 * @param publication - the TrackPublication
 * @param ref - the DOM reference that will be used to attach the track
 */
function handleTrack(
    publication: LocalTrackPublication | RemoteTrackPublication,
    ref: MutableRefObject<any>,
    {
        videoSubscribedCb,
        videoUnsubscribedCb,
        audioSubscribedCb,
        audioUnsubscribedCb,
    }: callbacks = {},
    trackStatusCb: (
        name: string,
        participant: Participant,
        track: Track,
        enabled: boolean,
    ) => void,
    participant: Participant,
) {
    const pubTrack = publication.track
    // If the TrackPublication is already subscribed to, then attach the Track to the DOM.
    if (pubTrack) {
        attachTrack(pubTrack, ref)
        pubTrack.kind === 'audio' && audioSubscribedCb && audioSubscribedCb()
        pubTrack.kind === 'video' && videoSubscribedCb && videoSubscribedCb()
    }

    // Once the TrackPublication is subscribed to, attach the Track to the DOM.
    publication.on('subscribed', (track) => {
        attachTrack(track, ref)
        track.kind === 'audio' && audioSubscribedCb && audioSubscribedCb()
        track.kind === 'video' && videoSubscribedCb && videoSubscribedCb()
        if (track.kind === 'video') {
            trackStatusCb('trackSubscribed', participant, track, true)
        }
    })

    // Once the TrackPublication is unsubscribed from, detach the Track from the DOM.
    publication.on('unsubscribed', (track) => {
        detachTrack(track, ref)
        track.kind === 'audio' && audioUnsubscribedCb && audioUnsubscribedCb()
        track.kind === 'video' && videoUnsubscribedCb && videoUnsubscribedCb()
        if (track.kind === 'video') {
            trackStatusCb('trackUnSubscribed', participant, track, false)
        }
    })
}

/**
 * Attach a Track to the DOM.
 * @param track - the Track to attach
 * @param ref - the DOM reference that will be used to attach the track
 */
export async function attachTrack(
    track: LocalTrack | RemoteTrack,
    ref: MutableRefObject<any>,
): Promise<void> {
    try {
        const newTrack = track as LocalVideoTrack
        newTrack.attach(ref.current.querySelector(track.kind))
    } catch (e) {
        Sentry.captureMessage('attachTrack() error =' + track.kind)
        Sentry.captureException(e)
    }
}

/**
 * Detach a Track from the DOM.
 * @param track - the Track to be detached
 * @param ref - the DOM reference from which the track will be detached
 */
export function detachTrack(
    track: LocalTrack | RemoteTrack,
    ref: MutableRefObject<any>,
): void {
    const el = ref.current.querySelector(track.kind)
    if (el) {
        ;(track as LocalVideoTrack).detach(el)
        el.src = ''
    }
}

/*
 * Tracking Actions
 */
export async function trackNetworkQuality(
    networkLevel: number,
    who: string,
): Promise<void> {
    if (networkLevel <= 3) {
        samaApi
            .trackEvent('network_issue', {
                networkQualityLevel: networkLevel,
                who: who,
            })
            .catch((e) => {
                Sentry.captureException(e)
            })
    }
}
