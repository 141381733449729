import { stripHtml } from 'string-strip-html'
import { ChatMessage, IGroup } from '../models/response/channel'
import { formatDate } from './misc'

export const groupMessagesByDate = (
    messages: ChatMessage[],
    dateFormat: string = 'EEE d MMM yyyy',
) => {
    return messages.reduce((groups, message) => {
        const date = formatDate(message.dateCreated as Date, dateFormat)
        if (!groups[date]) {
            groups[date] = []
        }

        groups[date].push(message)
        return groups
    }, {} as IGroup)
}

export const sortMessages = (groupedMessages: IGroup) => {
    return Object.keys(groupedMessages).sort(
        (a: string, b: string) => new Date(a).getTime() - new Date(b).getTime(),
    )
}

export const getSummaryLine = (content: string, maxLength: number) => {
    if (content) {
        return stripHtml(content).result.substring(0, maxLength)
    } else {
        return ''
    }
}
