import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import chatIcon from '../../../assets/icons/chat.svg'
import noteIcon from '../../../assets/icons/note.svg'
import securityIcon from '../../../assets/icons/security.svg'
import userIcon from '../../../assets/icons/user.svg'
import { PrimaryButton } from '../../../components/buttons/primary-button'
import { CenterModal } from '../../../components/modals'

interface Props {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    onClick: () => void
    actionBtnText: string
    textHeader: string
}

interface INoteContent {
    title: string
    content: string
    icon?: string
}

export const NoteTakerModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen, onClick, actionBtnText, textHeader }) => {
        const { t } = useTranslation()

        return (
            <CenterModal
                isOpen={isOpen}
                setIsOpen={() => setIsOpen(false)}
                className="max-w-[32rem] rounded-3xl"
                showCloseButton={false}
            >
                <div className="px-4 lg:px-8 pb-5 flex flex-col items-center justify-center">
                    {textHeader && (
                        <div className="flex flex-col mt-4 text-center">
                            <span className="text-2xl text-[#002b38] font-extrabold">
                                {textHeader}
                            </span>
                        </div>
                    )}

                    <div className="flex flex-col items-center justify-center pt-8 pb-4">
                        <NoteContentInfo
                            title={t('modals.noteTaker.firstFeature.title')}
                            content={t('modals.noteTaker.firstFeature.content')}
                            icon={noteIcon}
                        />
                        <NoteContentInfo
                            title={t('modals.noteTaker.secondFeature.title')}
                            content={t(
                                'modals.noteTaker.secondFeature.content',
                            )}
                            icon={chatIcon}
                        />
                        <NoteContentInfo
                            title={t('modals.noteTaker.thirdFeature.title')}
                            content={t('modals.noteTaker.thirdFeature.content')}
                            icon={securityIcon}
                        />
                        <NoteContentInfo
                            title={t('modals.noteTaker.fourthFeature.title')}
                            content={t(
                                'modals.noteTaker.fourthFeature.content',
                            )}
                            icon={userIcon}
                        />
                    </div>

                    <PrimaryButton
                        className="shadow mt-[15px] w-full"
                        onClick={onClick}
                    >
                        <span>{actionBtnText}</span>
                    </PrimaryButton>

                    <span className="text-red-400 text-[14px] font-semibold py-4">
                        {t('modals.noteTaker.disableText')}
                    </span>
                    <span className="text-[13px] text-[#002b38] text-center w-[280px]">
                        {t('modals.noteTaker.footerText')}
                    </span>
                </div>
            </CenterModal>
        )
    },
)

export const NoteContentInfo: React.FC<INoteContent> = ({
    title,
    content,
    icon,
}) => {
    return (
        <div className="flex mb-[8px] lg:w-[420px] space-x-5">
            <div>
                <img src={icon} width={45} height={45} />
            </div>
            <div className="flex flex-col">
                <span className="text-[16px] text-[#002b38] font-semibold">
                    {title}
                </span>
                <p className="text-[14px] text-[#002b38] pt-1">{content}</p>
            </div>
        </div>
    )
}
