import * as Sentry from '@sentry/browser'
import * as D from 'date-fns'
import { MutableRefObject } from 'react'
import Video, {
    LocalAudioTrack,
    Participant,
    RemoteAudioTrack,
    Room,
    Track,
    createLocalAudioTrack,
} from 'twilio-video'
import { Action, IContext, TEvent } from '../stores/bookingMachine/config'
import {
    Booking,
    Coach,
    ParticipantDetail,
    ParticipantRefs,
    Session,
    Booking as TBooking,
    User,
} from '../types/SamaApi'
import {
    validateBooking,
    validateDevice as validateDeviceUtil,
    validateToken,
} from '../utils/validation'

import { t } from 'i18next'
import { NotificationType } from '../utils/constants'
import { stores, useStores } from '../utils/stores'
import samaApi from './SamaApi'
import { handleParticipants } from './twilio'

export async function getBookingAndMe({
    bookingId,
    token,
}: IContext): Promise<{ booking: TBooking; user: User; isLate: boolean }> {
    try {
        if (token) {
            await validateToken(token)
        }

        const [booking, user] = await Promise.all([
            samaApi.getBooking(bookingId as string),
            samaApi.getMe(),
        ])

        const isLate = D.isPast(D.addMinutes(booking.date, 5))

        await validateBooking(booking)
        return { booking, user, isLate }
    } catch (error) {
        if (error !== 'tooLate' && error !== 'tooEarly') {
            Sentry.captureException(error)
            throw new Error('apiError')
        }
        throw error
    }
}

export async function loadDevice(
    context: IContext,
    event: TEvent,
): Promise<void> {
    const payload = (event as any).payload
    const isAudioOn = payload.isAudioOn
    const isVideoOn = payload.isVideoOn
    const inDebugMode = context.inDebugMode

    if (payload) {
        const room = payload.room as Room

        if (payload.type === 'speaker') {
            let tracks: (LocalAudioTrack | RemoteAudioTrack | null)[] = []

            room.participants.forEach((part: Participant) => {
                const foundTracks = Array.from(part.audioTracks.values()).map(
                    (trackPublication) => trackPublication.track,
                )
                tracks = [...tracks, ...foundTracks]
            })

            if (tracks.length > 0) {
                tracks.forEach(function (track) {
                    if (track) {
                        track.detach().forEach((detachedElement) => {
                            detachedElement.remove()
                        })
                    }
                })

                tracks.forEach(function (track) {
                    if (track) {
                        const audioElement = track.attach() as any
                        audioElement.setSinkId(payload.device)
                    }
                })
            }
        } else if (payload.type === 'microphone') {
            room.localParticipant.audioTracks.forEach((publication) => {
                publication.track.stop()
                room.localParticipant.unpublishTrack(publication.track)
            })
            const track = await createLocalAudioTrack({
                deviceId: { exact: payload.device },
            })

            room.localParticipant.publishTrack(track)

            // if the audio was mutted
            // before chanign the device
            // thank make sure the new one
            // is also muted
            if (!isAudioOn) {
                track.disable()
            }
        } else if (payload.type === 'camera') {
            // This has been migrated to reack hooks in the companant
            if (inDebugMode) {
                Sentry.captureMessage('loadDevice() - camera')
                Sentry.captureMessage(
                    'loadDevice() - camera details' + JSON.stringify(payload),
                )
                Sentry.captureMessage(
                    'loadDevice() - isVideoOn="' + isVideoOn + '"',
                )
            }
        }
    }
}

export function handleRoom({
    twilioRoom,
    localRef,
    participantRefs,
}: IContext) {
    return (
        callback: (event: TEvent) => void,
    ): (() => Video.Room | undefined) => {
        handleParticipants({
            room: twilioRoom as Room,
            localRef: localRef as MutableRefObject<any>,
            participantRefs: participantRefs as ParticipantRefs[],
            updateNetworkQualityCb: (value: number, identity: string) => {
                callback({
                    type: Action.updateNetworkQuality,
                    value: value,
                    identity: identity,
                })
            },
            remoteConnectedCb: () =>
                callback({
                    type: Action.coachConnects,
                }),
            remoteDisconnectedCb: () =>
                callback({
                    type: Action.coachDisconnects,
                }),
            dominantSpeakerChangedCb: (participant: Participant) => {
                callback({
                    type: Action.dominantSpeakerChanged,
                    participant: participant,
                })
            },
            trackStatusCb: (
                name: string,
                participant: Participant,
                track: Track,
                enabled: boolean,
            ) => {
                callback({
                    type: Action.chanceTrackStatus,
                    name: name,
                    participant: participant,
                    track: track,
                    enabled: enabled,
                })
            },
            remoteVideoSubscribedCb: () =>
                callback({
                    type: Action.subscribeCoachVideo,
                }),
            remoteVideoUnsubscribedCb: () =>
                callback({
                    type: Action.unsubscribeCoachVideo,
                }),
            remoteAudioSubscribedCb: () =>
                callback({
                    type: Action.subscribeCoachAudio,
                }),
            remoteAudioUnsubscribedCb: () =>
                callback({
                    type: Action.unsubscribeCoachAudio,
                }),
        })

        // perform cleanup
        return () => twilioRoom?.disconnect()
    }
}

async function connectAudioOnlySession({
    bookingId,
    booking,
}: IContext): Promise<{
    coach?: User
    twilioRoom: Room
    participantDetails: ParticipantDetail[]
}> {
    try {
        if (!booking) {
            throw new Error('Could not find booking')
        }
        const session = await samaApi.getSession(bookingId as string)
        configureTwilioLogging(booking, session)
        const [coachId] = session.name.split('_')
        const [twilioRoom, coachInfo, participantDetails] = await Promise.all([
            Video.connect(session.token, {
                name: session.name,
                audio: true,
                maxAudioBitrate: 16000,
                dominantSpeaker: true,
                bandwidthProfile: {
                    video: {
                        mode: 'grid',
                    },
                },
                networkQuality: {
                    local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
                    remote: 1, // RemoteParticipants' Network Quality verbosity [0 - 3]
                },
            }),
            samaApi.getCoach(coachId),
            samaApi.getSessionParticipantDetails(bookingId as string),
        ])
        return {
            coach: coachInfo as Coach as User,
            twilioRoom,
            participantDetails,
        }
    } catch (error) {
        Sentry.captureMessage('Connecting to audio session error')
        Sentry.captureException(error)
        throw new Error('connectionError')
    }
}

export async function connectSession({
    bookingId,
    booking,
}: IContext): Promise<{
    coach?: User
    twilioRoom: Room
    participantDetails: ParticipantDetail[]
}> {
    try {
        if (!booking) {
            throw new Error('Could not find booking')
        }
        const session = await samaApi.getSession(bookingId as string)
        configureTwilioLogging(booking, session)
        const [coachId] = session.name.split('_')
        const [twilioRoom, coachInfo, participantDetails] = await Promise.all([
            Video.connect(session.token, {
                name: session.name,
                audio: true,
                video: { height: 360, frameRate: 15, width: 640 },
                maxAudioBitrate: 16000,
                dominantSpeaker: true,
                bandwidthProfile: {
                    video: {
                        mode: 'grid',
                    },
                },
                networkQuality: {
                    local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
                    remote: 1, // RemoteParticipants' Network Quality verbosity [0 - 3]
                },
            }),
            samaApi.getCoach(coachId),
            samaApi.getSessionParticipantDetails(bookingId as string),
        ])
        return {
            coach: coachInfo as Coach as User,
            twilioRoom,
            participantDetails,
        }
    } catch (error: any) {
        if (
            error.name === 'NotAllowedError' ||
            error.name === 'NotFoundError'
        ) {
            return connectAudioOnlySession({ bookingId, booking } as IContext)
        }

        Sentry.captureMessage('Connecting to session error')
        Sentry.captureException(error)
        throw new Error('connectionError')
    }
}

async function configureTwilioLogging(
    booking: Booking,
    session: Session,
): Promise<void> {
    const { Logger } = Video
    const logger = Logger.getLogger('twilio-video')

    logger.methodFactory = () => {
        return function (datetime, logLevel, component, message, data) {
            try {
                const logMessage = {
                    datetime,
                    logLevel,
                    component,
                    message,
                    data,
                    booking: {},
                    session: {},
                }

                if (booking) {
                    logMessage.booking = {
                        _id: booking._id,
                        duration: booking.duration,
                    }
                }
                if (session) {
                    logMessage.session = {
                        name: session.name,
                        sid: session.sid,
                    }
                }
                samaApi.logEvent(logMessage)
            } catch (error) {
                Sentry.captureException(error)
            }
        }
    }
    logger.setLevel('info')
}

export async function validateDevice(): Promise<void> {
    return validateDeviceUtil(navigator.userAgent)
}

export async function postRating(
    { bookingId }: IContext,
    event: TEvent,
): Promise<Session> {
    return samaApi.rateSession(
        bookingId as string,
        (event as any).payload.rating,
    )
}

async function checkUserAudioMedia({ inDebugMode }: IContext): Promise<{
    audioDevice: string | undefined
    videoDevice: undefined
}> {
    return navigator.mediaDevices
        .getUserMedia({
            audio: true,
        })
        .then(function (stream) {
            const tracks = stream.getTracks()

            if (inDebugMode) {
                Sentry.captureMessage(
                    'checkUserMedia() - audioDevice=' +
                        JSON.stringify(tracks[0].getSettings()),
                )
                Sentry.captureMessage(
                    'checkUserMedia() - audioDeviceId=' +
                        tracks[0].getSettings().deviceId,
                )
            }

            return {
                audioDevice: tracks[0].getSettings().deviceId,
                videoDevice: undefined,
            }
        })
        .catch((e) => {
            Sentry.captureMessage('getUserAudioMedia() error')
            Sentry.captureException(e)

            navigator.mediaDevices.enumerateDevices().then((devices) => {
                Sentry.captureMessage(
                    'getUserAudioMedia() error devices' +
                        JSON.stringify(devices),
                )
            })
            throw e
        })
}

export async function checkUserMedia({ inDebugMode }: IContext): Promise<{
    audioDevice: string | undefined
    videoDevice: string | undefined
}> {
    return navigator.mediaDevices
        .getUserMedia({
            audio: true,
            video: {
                facingMode: 'user',
                width: {
                    min: 640,
                    ideal: 1280,
                    max: 1920,
                },
            },
        })
        .then(function (stream) {
            const tracks = stream.getTracks()

            if (inDebugMode) {
                Sentry.captureMessage(
                    'checkUserMedia() - videoDevice=' +
                        JSON.stringify(tracks[1].getSettings()),
                )
                Sentry.captureMessage(
                    'checkUserMedia() - audioDevice=' +
                        JSON.stringify(tracks[0].getSettings()),
                )
                Sentry.captureMessage(
                    'checkUserMedia() - audioDeviceId=' +
                        tracks[0].getSettings().deviceId,
                )
            }
            const { session } = useStores()
            if (!session.localVideoTrack) {
                session.setLocalVideoTrack(tracks[1])
            }
            return {
                audioDevice: tracks[0].getSettings().deviceId,
                videoDevice: tracks[1].getSettings().deviceId,
            }
        })
        .catch((e) => {
            if (e.name === 'NotAllowedError' || e.name === 'NotFoundError') {
                return checkUserAudioMedia({ inDebugMode } as IContext)
            }

            Sentry.captureMessage('getUserMedia() error')
            Sentry.captureException(e)

            if (e.name === 'NotReadableError') {
                stores.notifications.createNotification(
                    NotificationType.ERROR,
                    t('Session.cameraInUse'),
                )
            }

            navigator.mediaDevices.enumerateDevices().then((devices) => {
                Sentry.captureMessage(
                    'getUserMedia() error devices' + JSON.stringify(devices),
                )
            })
            throw e
        })
}

/*
 * Twilio Actions
 */
export function disableAudio({ twilioRoom }: IContext): void {
    twilioRoom?.localParticipant.audioTracks.forEach((publication) => {
        publication.track.disable()
    })
}

export function enableAudio({ twilioRoom }: IContext): void {
    twilioRoom?.localParticipant.audioTracks.forEach((publication) => {
        publication.track.enable()
    })
}

export function disableVideo({ twilioRoom }: IContext): void {
    twilioRoom?.localParticipant.videoTracks.forEach((publication) => {
        publication.track.disable()
    })
}

export function enableVideo({ twilioRoom }: IContext): void {
    twilioRoom?.localParticipant.videoTracks.forEach((publication) => {
        publication.track.enable()
    })
}

export async function disconnect({
    twilioRoom,
    bookingId,
}: IContext): Promise<void> {
    await Promise.all([
        twilioRoom?.disconnect(),
        samaApi.endBooking(bookingId as string),
    ])
}

/*
 * Tracking Actions
 */
export async function trackConnected({
    bookingId,
    booking,
    user,
    isLate,
}: IContext): Promise<void> {
    samaApi
        .trackEvent('session_live_coaching_connected', {
            booking_id: bookingId,
            join_time: new Date(),
            is_late: isLate,
            is_video_session: booking?.isVideoSession,
            booking_date: booking?.date,
            booking_duration: booking?.duration,
            coachee_company_id: user?.company,
        })

        .catch((e) => {
            Sentry.captureException(e)
        })
}

export async function trackDisconnected({
    bookingId,
    booking,
    user,
}: IContext): Promise<void> {
    samaApi
        .trackEvent('session_live_coaching_disconnected', {
            booking_id: bookingId,
            booking_date: booking?.date,
            booking_duration: booking?.duration,
            coachee_company_id: user?.company,
        })

        .catch((e) => {
            Sentry.captureException(e)
        })
}

export async function trackError(
    { bookingId }: IContext,
    event: TEvent,
): Promise<void> {
    samaApi
        .trackEvent('session_live_coaching_error', {
            booking_id: bookingId,
            error: (event as any).toString(),
        })

        .catch((e) => {
            Sentry.captureException(e)
        })
}

export async function trackRatingOpen({ bookingId }: IContext): Promise<void> {
    samaApi
        .trackEvent('video_quality_rating_open', { booking_id: bookingId })

        .catch((e) => {
            Sentry.captureException(e)
        })
}

export async function trackRatingSent(
    { bookingId }: IContext,
    event: TEvent,
): Promise<void> {
    samaApi
        .trackEvent('video_quality_rating', {
            rate: (event as any).payload.rating,
            booking_id: bookingId,
        })

        .catch((e) => {
            Sentry.captureException(e)
        })
}
