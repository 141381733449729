import { isFunction } from 'formik'
import { forEach, values } from 'lodash'
import OneSignal from 'react-onesignal'
import {
    AuthStore,
    BookingStore,
    ChannelStore,
    CoacheeStore,
    ImageStore,
    NoteStore,
    NotificationsStore,
    QuestionsStore,
    SessionStore,
    StaticsStore,
    TaskStore,
} from '../stores'

type Stores = {
    auth: AuthStore
    notifications: NotificationsStore
    statics: StaticsStore
    coachee: CoacheeStore
    booking: BookingStore
    image: ImageStore
    note: NoteStore
    task: TaskStore
    question: QuestionsStore
    channel: ChannelStore
    session: SessionStore

    // Store functions
    reset: () => void
}

export const stores: Stores = {
    auth: new AuthStore(),
    notifications: new NotificationsStore(),
    statics: new StaticsStore(),
    coachee: new CoacheeStore(),
    booking: new BookingStore(),
    image: new ImageStore(),
    note: new NoteStore(),
    task: new TaskStore(),
    question: new QuestionsStore(),
    channel: new ChannelStore(),
    session: new SessionStore(),

    reset(): void {
        OneSignal.logout()
        forEach(values(this), (value: any) => {
            const reset = value.reset

            if (reset && isFunction(reset)) {
                reset()
            }
        })
    },
}

export const useStores = () => {
    return stores
}
