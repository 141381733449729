import { action, makeAutoObservable } from 'mobx'

import { LocalVideoTrack } from 'twilio-video'
import {
    Resettable,
    dehydrateToStorage,
    hydrateFromStorage,
} from '../utils/misc'
const VIDEO_SETTINGS_KEY = 'SAMA:VIDEO_SETTINGS'

interface VideoSettings {
    deviceId: string | null
    isBlurred: boolean
    blurLevel: 'low' | 'medium' | 'high'
}

const defaultVideoSettings: VideoSettings = {
    deviceId: null,
    isBlurred: false,
    blurLevel: 'low',
}

export class SessionStore implements Resettable {
    public videoSettings: VideoSettings = defaultVideoSettings
    public localVideoTrack: null | LocalVideoTrack = null

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
        this.setUp()
    }

    @action
    public setLocalVideoTrack(videoTrack: MediaStreamTrack): void {
        this.localVideoTrack = new LocalVideoTrack(videoTrack)
    }

    @action
    public setDeviceId(deviceId: string): void {
        this.videoSettings.deviceId = deviceId
        dehydrateToStorage(VIDEO_SETTINGS_KEY, this.videoSettings)
    }

    @action
    public setBlurLevel(blurLevel: 'low' | 'medium' | 'high'): void {
        this.videoSettings.blurLevel = blurLevel
        dehydrateToStorage(VIDEO_SETTINGS_KEY, this.videoSettings)
    }

    @action
    public get deviceId(): string | null {
        return this.videoSettings.deviceId
    }

    @action
    public get blurLevel(): 'low' | 'medium' | 'high' {
        return this.videoSettings.blurLevel
    }

    @action
    public setIsBlurred(isBlurred: boolean): void {
        this.videoSettings.isBlurred = isBlurred
        dehydrateToStorage(VIDEO_SETTINGS_KEY, this.videoSettings)
    }

    @action
    public get isBlurred(): boolean {
        return this.videoSettings.isBlurred
    }

    @action
    public setUp(): void {
        this.videoSettings =
            hydrateFromStorage(VIDEO_SETTINGS_KEY) ?? defaultVideoSettings
    }

    @action
    public reset(): void {
        // dont reset the settings as they are as we can use them in the next session.
        // this.videoSettings = defaultVideoSettings
        // removeFromStorage(VIDEO_SETTINGS_KEY)
    }
}
