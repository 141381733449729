import i18next, { t } from 'i18next'
import { get, setWith } from 'lodash'
import { action, computed, makeAutoObservable, runInAction } from 'mobx'
import { tap } from 'rxjs'
import { GoalsModel } from '../models/request'
import { AccountModels } from '../models/request/coachee/account.model'
import { ChangeMyCoachModel } from '../models/request/coachee/change-my-coach.model'
import { NPSRatingModel } from '../models/request/coachee/nps-rating.model'
import { RateCoachModel } from '../models/request/coachee/rate-coach.model'
import { OnboardingModels } from '../models/request/onboarding'
import {
    CheckList,
    Coachee,
    CoacheeNoteTaker,
    CoacheePopups,
    Events,
    MissedSession,
    OnboardingOptionsResponse,
    OnboardingQuestion,
    OnboardingStage,
    TaskCompleted,
    TotalSession,
} from '../models/response'
import { AssessmentStats } from '../models/response/coachee/assessment-stats'
import { Goal } from '../models/response/coachee/goals'
import { CheckIns } from '../models/response/questions/outcome-assessment-questions'
import { Company, Settings, User } from '../models/response/user'
import { PushNotificationSettingsRequest } from '../services/push-notification'
import {
    HttpMethod,
    HttpStatusCode,
    NotificationType,
    Stage,
} from '../utils/constants'
import {
    Resettable,
    dehydrateToStorage,
    hydrateFromStorage,
    removeFromStorage,
} from '../utils/misc'
import { Response, request } from '../utils/request'
import { stores } from '../utils/stores'
import { getStaticsValues } from './statics.store'

const COACHEE_KEY = 'SAMA:COACHEE'

const ONBOARDING_KEY = 'SAMA:ONBOARDING'

export class CoacheeStore implements Resettable {
    public ready: boolean = true
    public coachee: Coachee | null = null
    public allowedCoachingLangugages: string[] = []
    public coacheeChecklist: CheckList | null = null
    public onboardingOptions: OnboardingOptionsResponse | null = null
    public onboardingStage: OnboardingStage = {
        stage: Stage.INITIAL,
        completed: false,
    }
    public coachAvailability: any = null
    public assessmentsStats: AssessmentStats | null = null
    public coacheePopups: CoacheePopups | null = null
    public goals: Goal[] = []
    public trackers: any = null
    public totalSessions: TotalSession[] = []
    public taskCompleted: TaskCompleted[] = []
    public missedSessions: MissedSession[] = []
    public checkIns: CheckIns[] = []

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
        this.setUp()
    }

    @computed
    public get hasCompletedAllRequiredOnboardingQuestions(): boolean {
        return (
            this.coachee !== null &&
            Boolean(this.coachee.industry) &&
            Boolean(this.coachee.function) &&
            Boolean(this.coachee.position) &&
            Boolean(this.coachee.desireCompetency) &&
            Boolean(this.coachee.coachGender) &&
            this.hasCompletedSelectCoachingLanguage &&
            Boolean(this.coachee.currentCoach._id)
        )
    }

    @computed
    public get hasCompletedSelectCoachingLanguage(): boolean {
        return (
            Boolean(this.allowedCoachingLangugages.length === 0) ||
            (this.coachee !== null && Boolean(this.coachee.coachingLanguage))
        )
    }

    @computed
    public get initials(): string {
        if (!this.onboardingOptions && !this.coacheeUser) {
            return 'N/A'
        }

        const firstName =
            this.onboardingOptions?.firstName ??
            this.coacheeUser?.firstName ??
            ''
        const lastName =
            this.onboardingOptions?.lastName ?? this.coacheeUser?.lastName ?? ''

        const firstInitial = firstName[0].toUpperCase()

        const lastInitial = lastName[0].toUpperCase()

        return firstInitial + lastInitial
    }

    @computed
    public get coachInitials(): string {
        if (!this.coachee?.currentCoach) {
            return 'N/A'
        }

        const firstName = this.coachee?.currentCoach.user.firstName ?? ''
        const lastName = this.coachee?.currentCoach.user?.lastName ?? ''

        const firstInitial = firstName[0].toUpperCase()

        const lastInitial = lastName[0].toUpperCase()

        return firstInitial + lastInitial
    }

    @computed
    public get coacheeUser(): User | undefined {
        return this.coachee?.user
    }

    public get coacheeCompany(): Company | undefined {
        return this.coachee?.user?.company
    }

    public get coacheeCompanySettings(): Settings | undefined {
        return this.coachee?.user?.company?.settings
    }

    @computed
    public get onboardingQuestions(): OnboardingQuestion | undefined {
        return this.onboardingOptions?.onboardingQuestions
    }

    @computed
    public get checklistEvent(): Events[] | undefined {
        return this.coacheeChecklist?.events
    }

    @action
    public setUp(): void {
        this.coachee = hydrateFromStorage(COACHEE_KEY)
        this.onboardingStage = hydrateFromStorage(ONBOARDING_KEY) ?? {
            stage: Stage.INITIAL,
            completed: false,
        }
    }

    @action
    public Launch() {
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
        return request<never, any>(
            `/me/launch?timezone=${timeZone}`,
            HttpMethod.POST,
        )
            .pipe(
                tap((response) =>
                    runInAction(() => {
                        if (response?.data?.companyLanguages) {
                            this.allowedCoachingLangugages =
                                response.data.companyLanguages
                        }
                    }),
                ),
            )
            .subscribe()
    }

    @action
    public async changeSiteLanguage(
        language: string,
        notify: boolean,
    ): Promise<void> {
        stores.statics.reset()
        await stores.statics.loadStatics(language)
        i18next.changeLanguage(language)
        if (notify) {
            stores.notifications.createNotification(
                NotificationType.INFO,
                t('messages.languageChanged'),
                3 * 1000 /* 3 seconds */,
            )
        }
    }

    @action
    public updatePushNotificationSettings(
        body: PushNotificationSettingsRequest,
    ) {
        return request(`/me/push-notification-settings`, HttpMethod.PUT, {
            body: body,
        }).subscribe()
    }

    @action
    public firstMatch() {
        return request<never, any>('/me/coachee/match', HttpMethod.POST).pipe(
            tap((response) =>
                runInAction(() => {
                    if (response.data && this.coachee) {
                        this.setCoachee({
                            ...this.coachee,
                            currentCoach: (response.data as any).coach,
                        })
                    }
                }),
            ),
        )
    }

    @action
    public updateCoachee(model: OnboardingModels) {
        return request('/me/coachee', HttpMethod.PUT, {
            body: model.getRequestBody(),
        })
    }

    @action
    public setCoachee(coachee: Coachee): void {
        this.coachee = this.mapSatitcs(coachee)

        // DO NOT cache the coachee in local storage
        // as it contains sensitive information.
        // We could cache this is memory but do not persis
        // to local storage.
        // dehydrateToStorage(COACHEE_KEY, coachee)
    }

    @action
    private mapSatitcs(coachee: Coachee): Coachee {
        const arrayOfStatics = [
            {
                static: 'competencies',
                key: 'currentCoach.areasCompetencies',
            },
            {
                static: 'accreditations',
                key: 'currentCoach.coachingAccreditations',
            },
            {
                static: 'memberships',
                key: 'currentCoach.coachingMemberships',
            },
        ]

        arrayOfStatics.map((data) =>
            setWith(
                coachee,
                data.key,
                getStaticsValues(
                    get(stores.statics, data.static),
                    get(coachee, data.key),
                ),
            ),
        )

        return coachee
    }

    @action
    public setOnboardingStage(stage: Stage, completed: boolean = false): void {
        const onboarding = { stage, completed }
        this.onboardingStage = onboarding

        dehydrateToStorage(ONBOARDING_KEY, onboarding)
    }

    @action
    public getOnboardingOptions() {
        return request('/me/coachee/onboarding-options', HttpMethod.GET).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        if (response.data) {
                            this.onboardingOptions = response.data
                        }
                    }
                })
            }),
        )
    }

    @action
    public getChecklistEvent() {
        return request('/me/checklist-events', HttpMethod.GET).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        if (response.data) {
                            const data: any = response.data

                            if (!data.events || data.events.length === 0) {
                                data.total = 0
                                data.numberOfCompletedEvents = 0
                                data.numberOfIncompleteEvents = 0
                            }

                            this.coacheeChecklist = data
                        }
                    }
                })
            }),
        )
    }

    @action
    public getCoachee() {
        this.ready = false

        return request('/me/coachee', HttpMethod.GET, {
            silentErrors: true,
        }).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.setCoachee(response.data)
                    } else if (
                        [HttpStatusCode.FORBIDDEN].includes(response.status)
                    ) {
                        stores.auth.signOut()
                    }
                    this.ready = true
                })
            }),
        )
    }

    @action
    public rateCoach(model: RateCoachModel) {
        return request(
            `/coachee/${this.coachee?._id}/rate-my-coach`,
            HttpMethod.POST,
            { body: model },
        )
    }

    @action
    public npsRating(model: NPSRatingModel) {
        return request(
            `/coachee/${this.coachee?._id}/rate/nps`,
            HttpMethod.POST,
            { body: model },
        )
    }

    @action
    public getPopups() {
        return request(
            `/coachee/${this.coachee?._id}/popups`,
            HttpMethod.GET,
        ).pipe(
            tap((response: Response<CoacheePopups | null>) => {
                runInAction(() => {
                    if (response.ok) {
                        if (response.data) {
                            this.coacheePopups = response.data
                        }
                    }
                })
            }),
        )
    }

    @action
    public askRecordingPermission(coacheeId: string) {
        return request(
            `/coachee/${coacheeId}/ask-recording-permission`,
            HttpMethod.GET,
        ).pipe(
            tap((response: Response<CoacheeNoteTaker | null>) => {
                runInAction(() => {
                    if (response.ok && response.data) {
                        if (this.coachee) {
                            this.coachee.coacheeRecordingPermission =
                                response.data.canAskPermissionToRecordSession
                        }
                    }
                })
            }),
        )
    }

    @action
    public getCoachAvailablility(coachId: string, month: string) {
        return request<never, any[]>(
            `/coach/${coachId}/availabilities/available-session/${month}`,
            HttpMethod.GET,
        ).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.coachAvailability = response.data
                    }
                })
            }),
        )
    }

    @action
    public getOAStats() {
        if (this.coachee) {
            return request<never, any>(
                `/coachee/${this.coachee._id}/experience/stats`,
                HttpMethod.GET,
            ).pipe(
                tap((response) => {
                    runInAction(() => {
                        if (response.data) {
                            this.assessmentsStats = response.data
                        }
                    })
                }),
            )
        }
    }

    @action
    public getCoacheeGoals() {
        return request('/me/coachee/goal', HttpMethod.GET, {}).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.goals = response.data
                    }
                })
            }),
        )
    }

    @action
    public addGoal(modal: GoalsModel) {
        return request('/me/coachee/goal', HttpMethod.POST, {
            body: modal,
        })
    }

    @action
    public editGoal(modal: GoalsModel, goalId: string) {
        return request(`/goal/${goalId}`, HttpMethod.PUT, {
            body: modal,
        })
    }

    @action
    public deleteGoal(goalId: string) {
        return request(`/goal/${goalId}`, HttpMethod.DELETE, {}).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.ok) {
                        this.getCoacheeGoals().subscribe()
                    }
                })
            }),
        )
    }

    @action
    public getTrackerData() {
        if (this.coachee) {
            return request<never, any>(
                `/coachee/${this.coachee._id}/experience/trackers?listDates=true`,
                HttpMethod.GET,
            ).pipe(
                tap((response) => {
                    runInAction(() => {
                        if (response.data) {
                            this.trackers = response.data
                        }
                    })
                }),
            )
        }
    }

    @action
    public getCompletedSession() {
        return request('/me/coachee/bookings-completed', HttpMethod.GET).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.totalSessions = response.data
                    }
                })
            }),
        )
    }

    @action
    public getCompletedTask() {
        return request('/me/coachee/task-completed', HttpMethod.GET).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.taskCompleted = response.data
                    }
                })
            }),
        )
    }

    @action
    public getMissedSession() {
        return request('/me/coachee/bookings-missed', HttpMethod.GET).pipe(
            tap((response) => {
                runInAction(() => {
                    if (response.data) {
                        this.missedSessions = response.data
                    }
                })
            }),
        )
    }

    @action
    public getCheckIns() {
        if (this.coachee) {
            return request(
                `/coachee/${this.coachee._id}/assessment/history`,
                HttpMethod.GET,
            ).pipe(
                tap((response) => {
                    runInAction(() => {
                        if (response.data) {
                            this.checkIns = response.data
                        }
                    })
                }),
            )
        }
    }

    @action
    public updatePersonalCoachee(model: AccountModels) {
        return request('/me/coachee', HttpMethod.PUT, {
            body: model.getRequestBody(),
        })
    }

    @action
    public reMatch(data: ChangeMyCoachModel) {
        return request('/me/coachee/match', HttpMethod.PUT, {
            body: data,
        }).pipe(
            tap((response) =>
                runInAction(() => {
                    if (response.data && this.coachee) {
                        this.setCoachee({
                            ...this.coachee,
                            currentCoach: (response.data as any).coach,
                        })
                    }
                }),
            ),
        )
    }

    @action
    public deleteCoachee() {
        return request('/me/coachee', HttpMethod.DELETE, {})
    }

    @action
    public reset(): void {
        this.coachee = null
        this.onboardingOptions = null
        this.coacheeChecklist = null
        this.goals = []
        this.onboardingStage = {
            stage: Stage.INITIAL,
            completed: false,
        }
        removeFromStorage(ONBOARDING_KEY)
        removeFromStorage(COACHEE_KEY)
    }

    @action
    public signOut(): void {
        stores.reset()
    }
}
