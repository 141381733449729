import { Editor } from '@tinymce/tinymce-react'
import { Formik } from 'formik'
import { observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ExclamationWarning from '../../../assets/icons/exclamation-gray-slanted.svg'
import removeIcon from '../../../assets/icons/trash.svg'
import { PrimaryButton } from '../../../components/buttons/primary-button'
import { CenterModal } from '../../../components/modals/center-modal'
import { NoteModel } from '../../../models/request/note/note.model'
import { NoteResponse } from '../../../models/response/note/note'
import { trackEvent } from '../../../services/event-tracking'
import { countWords } from '../../../utils/chatHelper'
import { NotificationType } from '../../../utils/constants'
import { classNames } from '../../../utils/misc'
import { getSummaryLine } from '../../../utils/sort'
import { useStores } from '../../../utils/stores'
import { validateModel } from '../../../utils/validation/validate-model'
import { ConfirmationModal } from '../../dashboard/modals'
interface Props {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    timePeriod?: string
    currentItem?: NoteResponse
    setCurrentItem?: (item: NoteResponse | undefined) => void
}

export const NoteModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen, currentItem, setCurrentItem }) => {
        const { t } = useTranslation()
        const [editedTopicInput, setEditedTopicInput] = useState('')
        const { note, coachee, notifications } = useStores()
        const [deleteNote, setDeleteNote] = useState(false)
        useEffect(() => {
            setEditedTopicInput(
                currentItem?.title ||
                    getSummaryLine(currentItem?.content ?? '', 100),
            )
            trackEvent('screen_viewed', {
                name: 'my_notes_viewed',
                coachee_id: coachee.coachee?._id,
            })
        }, [coachee.coachee?._id, currentItem?.content, currentItem?.title])

        const editorSettings = {
            menubar: false,
            inline: false,
            plugins: ['insertdatetime', 'lists'],
            paste_as_text: true,
            color_cols: 6,
            color_map: [
                '#FF2600',
                'Red',
                '#FFFB00',
                'Yellow',
                '#00F900',
                'Green',
                '#FF40FF',
                'Purple',
                '#00FDFF',
                'Blue',
            ],
            custom_colors: false,
            toolbar: 'bold italic underline backcolor bullist numlist ',
            branding: false,
            statusbar: false,
            height: 350,
        }
        const close = useCallback(() => {
            setIsOpen(false)
            setCurrentItem && setCurrentItem(undefined)
        }, [setCurrentItem, setIsOpen])

        const onDeleteNote = useCallback(() => {
            if (currentItem && currentItem._id) {
                note.deleteNote(currentItem._id).subscribe({
                    next(response) {
                        if (response.ok) {
                            notifications.createNotification(
                                NotificationType.INFO,
                                t('modals.notes.notificationMessage'),
                                5 * 1000,
                            )
                            setCurrentItem && setCurrentItem(undefined)
                            note.getCoacheeNotes().subscribe()
                        }
                    },
                })
                setDeleteNote(false)
                setIsOpen(false)
            }
        }, [currentItem, note, notifications, setCurrentItem, setIsOpen, t])

        const onSubmit = useCallback(
            (values: NoteModel) => {
                const data = {
                    content: values.content,
                    title: editedTopicInput ?? null,
                }
                if (currentItem && currentItem._id) {
                    note.updateNote(
                        currentItem._id,
                        data as NoteModel,
                    ).subscribe({
                        next(response) {
                            if (response.ok) {
                                note.getCoacheeNotes().subscribe()
                            }
                        },
                    })
                } else {
                    note.createNote(data as NoteModel).subscribe({
                        next(response) {
                            if (response.ok) {
                                trackEvent('my_note_saved', {
                                    coach_id: coachee.coachee?.currentCoach._id,
                                    coachee_id: coachee.coachee?._id,
                                    nb_word: countWords(data.content),
                                })
                                note.getCoacheeNotes().subscribe()
                            }
                        },
                    })
                }
                setCurrentItem && setCurrentItem(undefined)
                setIsOpen(false)
            },
            [
                coachee.coachee?._id,
                coachee.coachee?.currentCoach._id,
                currentItem,
                editedTopicInput,
                note,
                setCurrentItem,
                setIsOpen,
            ],
        )
        return (
            <>
                <CenterModal
                    isOpen={isOpen}
                    setIsOpen={() => setIsOpen(false)}
                    className="max-w-[34rem]"
                    nodeComponent={
                        currentItem?._id && (
                            <div className="flex absolute left-8 top-6 w-full">
                                <input
                                    className={classNames(
                                        'focus:outline-none bg-transparent focus:bg-[#f8f8fe] py-2 px-2  z-30',
                                        'text-[15px] text-[#15304b] font-bold rounded-sm w-[70%]',
                                    )}
                                    value={editedTopicInput}
                                    onChange={(e) =>
                                        setEditedTopicInput(e.target.value)
                                    }
                                />
                                <img
                                    src={removeIcon}
                                    alt="delete-icon"
                                    className="cursor-pointer -mt-7 ml-14"
                                    onClick={() => setDeleteNote(true)}
                                />
                            </div>
                        )
                    }
                    headerTitle={
                        currentItem?._id
                            ? undefined
                            : t('modals.notes.createNote')
                    }
                    optionalClose={close}
                >
                    <Formik
                        validate={validateModel}
                        initialValues={new NoteModel()}
                        onSubmit={onSubmit}
                    >
                        {({
                            handleSubmit,
                            isSubmitting,
                            isValid,
                            isValidating,
                            setFieldValue,
                        }) => (
                            <form
                                onSubmit={handleSubmit}
                                className="flex flex-col px-8 py-4 bg-[#fafafa] mt-10"
                                data-testid="form"
                            >
                                <main className="space-y-2 mb-6 mt-2">
                                    <Editor
                                        tinymceScriptSrc="/assets/js/tinymce/tinymce.min.js"
                                        disabled={false}
                                        apiKey={
                                            import.meta.env
                                                .VITE_APP_TINY_MCE_API_KEY
                                        }
                                        onInit={(evt, editor) => {
                                            setFieldValue(
                                                'content',
                                                editor.getContent(),
                                            )
                                        }}
                                        onEditorChange={(newValue) => {
                                            setFieldValue('content', newValue)
                                        }}
                                        initialValue={
                                            currentItem?.content ?? ''
                                        }
                                        init={{
                                            ...editorSettings,
                                            toolbar_location: 'bottom',
                                            language:
                                                coachee.coachee?.user
                                                    ?.appLanguage === 'en'
                                                    ? 'en_US'
                                                    : 'fr_FR',
                                            language_url:
                                                coachee.coachee?.user
                                                    ?.appLanguage === 'en'
                                                    ? ''
                                                    : '/assets/js/tinymce/langs/fr_FR.js',
                                        }}
                                    />
                                </main>
                                <footer>
                                    <div>
                                        <PrimaryButton
                                            className="w-full mt-6"
                                            type="submit"
                                            loading={isSubmitting}
                                            disabled={!isValid || isValidating}
                                        >
                                            <span>{t('buttons.save')}</span>
                                        </PrimaryButton>
                                    </div>
                                </footer>
                            </form>
                        )}
                    </Formik>
                </CenterModal>
                <ConfirmationModal
                    isOpen={deleteNote}
                    setIsOpen={setDeleteNote}
                    onClick={onDeleteNote}
                    headerImg={ExclamationWarning}
                    message={t('modals.notes.noteDeleteMessage')}
                    actionBtnText={t('modals.notes.noteDeleteBtn')}
                    actionBtnCancelText={t('modals.notes.cancelDeleteNote')}
                />
            </>
        )
    },
)
